import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/satisfactory-viewer",
  "title": "Satisfactory Viewer",
  "started": "2022-02-01T00:00:00.000Z",
  "published": "2022-02-02T00:00:00.000Z",
  "backgroundImage": "python",
  "tags": ["games", "golang"],
  "layoutClass": "satisfactory-viewer",
  "thumbnail": "blog/2022-02-01-satisfactory-viewer/thumbnail.png"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "https://store.steampowered.com/app/526870/Satisfactory/"
      }}>{`Satisfactory`}</a>{` is a game that I have enjoyed playing recently
after being introduced to it by my cousin.`}</p>
    <p>{`It is a mostly first person factory building simulator - a 3D Factorio if you will.`}</p>
    <p>{`You play by exploiting resource nodes - iron ore, copper ore, limestone, etc, and build machines to convert them into
the various parts like iron plates, screws, wire, and concrete which are needed to progress the game and expand your
infrastructure.
As the game progresses, more resources can be exploited and more and more complex parts can be built from them.`}</p>
    <p>{`The game can be played solo or cooperatively with others - others can join your game while you're playing.
The developers recently released dedicated servers so I set one up so my cousin could play on our shared world even if I
was unable to at the time he wanted to play.`}</p>
    <h2>{`Dedicated Server`}</h2>
    <p>{`The server can be run on Windows or Linux, but before I had to look at how to run it, I came across the
`}<a parentName="p" {...{
        "href": "https://hub.docker.com/r/wolveix/satisfactory-server"
      }}>{`wolveix/satisfactory-server`}</a>{` docker container which did it all
for me.
As my home server setup already supports running docker containers, it was pretty easy to set up a new LXD container to run it
in with a volume to store the satisfactory data and saves in.`}</p>
    <span className="prism-title">tasks/main.yml</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` start the server
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`docker_container`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` satisfactory
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`image`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` wolveix/satisfactory`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`server`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`latest
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`pull`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`restart_policy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` always
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`volumes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"{{ satisfactory_data_dir }}:/config"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`MAXPLAYERS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"4"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`PGID`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"500"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`PUID`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1001"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`STEAMBETA`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`published_ports`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"7777:7777/udp"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"15000:15000/udp"`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"15777:15777/udp"`}</span></code></pre></div>
    <p>{`The volume is used to store game data required by the server. It is a volume so that the significant amount of data
needed (about 4.5GB) doesn't need to be downloaded each time the container is started.
The volume also contains the save files made by the game.`}</p>
    <h2>{`Viewer`}</h2>
    <p>{`As time progressed, I wished for a way to see what progress had been made in our world without having to load the game.
Luckily, there is an interactive map as part of the
`}<a parentName="p" {...{
        "href": "https://satisfactory-calculator.com/en/interactive-map"
      }}>{`Satisfactory Calculator`}</a>{`, and even better it's possible to
load save files to view current progress.
Best of all, is that it can load saves from URLs.`}</p>
    <p>{`So all that would be required was a script to read the save filenames, perhaps order them by date and generate links to
the Satisfactory Calculator with reference to a URL that the save file can be downloaded from.`}</p>
    <p>{`I wrote a quick proof of concept in PHP, but decided it was a nice little project to learn Golang with.
This seemed ideal as all of the logic had already been determined.
It would also simplify the deployment process - being able to distribute a single binary and execute it.`}</p>
    <p>{`I'm certainly no designer, but to be fair to the design of this project I just threw something together quickly.
Be thankful it's not just text links...`}</p>
    <div className="image-center">
  <Image alt="Satisfactory viewer screenshot" path="blog/2022-02-01-satisfactory-viewer/screenshot.png" mdxType="Image" />
    </div>
    <p>{`Clicking on the "View" button loads the interactive map in Satisfactory Calculator.`}</p>
    <div className="image-center">
  <Image alt="Interactive map" path="blog/2022-02-01-satisfactory-viewer/interactive-map.png" mdxType="Image" />
    </div>
    <p>{`A couple of CSP headers are required for the Satisfactory Calculator to fetch the save files.
I probably should have had this program serve them with the files, but I took the easy route out and made Nginx serve
them instead:`}</p>
    <span className="prism-title">nginx</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "nginx"
    }}><pre parentName="div" {...{
        "className": "language-nginx"
      }}><code parentName="pre" {...{
          "className": "language-nginx"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`add_header`}</span>{` Access`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Control`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`Allow`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Headers `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Access-Control-Allow-Origin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`add_header`}</span>{` Access`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Control`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`Allow`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`Origin `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"https://satisfactory-calculator.com"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2>{`Learning Golang`}</h2>
    <p>{`I have some basic experience with writing little go scripts for monitoring checks and metrics gathering for Sensu Go, so
I'm already reasonably comfortable with using the language.
However, I'm sure while picking it up (especially for this project) I'll have committed some Golang `}<em parentName="p">{`faux pas`}</em>{`.
This isn't meant to be a tutorial for how to be a great Go programmer.`}</p>
    <p>{`I thought I'd highlight some interesting things I learned on my journey.`}</p>
    <p>{`The whole project is published on Github - `}<a parentName="p" {...{
        "href": "https://github.com/JonEllis/satisfactory-viewer"
      }}>{`https://github.com/JonEllis/satisfactory-viewer`}</a>{` - in case there are mistakes
in my simplifying of these features for this post.`}</p>
    <h3>{`HTTP Server`}</h3>
    <p>{`Listening for HTTP requests is really easy using the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`net/http`}</code>{` module.`}</p>
    <span className="prism-title">net/http</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// When a request starting with \`/\` arrives, handle it with the \`index\` function.`}</span>{`
http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`HandleFunc`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// said index function`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ResponseWriter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` req `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Request`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	fmt`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Fprintln`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"The response body"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// listen on port 1234 for HTTP requests`}</span>{`
http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ListenAndServe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`":1234"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`It is also very easy to serve files from the Satisfactory save directory.
Like with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http.HandleFunc`}</code>{`, there is a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http.FileServer`}</code>{` which takes a path to serve.`}</p>
    <span className="prism-title">file serving</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}>{`http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`HandleFunc`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` index`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

fileServer `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`FileServer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Dir`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`savePath`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Handle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/path/"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`StripPrefix`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/path"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` fileServer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ListenAndServe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`":1234"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Going back to the HTML listing page, for more complex html documents, templates can be utilised using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`html/template`}</code>{`.`}</p>
    <span className="prism-title">html/template</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` MyPageData `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`struct`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    Some `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{`
    Data `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ResponseWriter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` req `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Request`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
	tmpl `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` template`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Must`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`template`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ParseFiles`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"template.html"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` MyPageData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    Some`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"This is my value"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    Data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Yet another value"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  tmpl`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Execute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Then the template can be processed and variables injected.`}</p>
    <span className="prism-title">template.html</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`An Example`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`head`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dl`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dt`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Some`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dt`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dd`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`{{ .Some }}`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dd`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dt`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Data`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dt`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`dd`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`{{ .Data }}`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dd`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`dl`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
  `}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h2>{`Embed Files`}</h2>
    <p>{`A caveat is that the template file needs to be available relative to the built executable.
Luckily, since Golang 1.16, it is possible to embed files into the go program.
This allows the template to be used without having to distribute it with the binary.`}</p>
    <span className="prism-title">embed</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` templates embed`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`FS

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`type`}</span>{` MyPageData `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`struct`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    Some `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{`
    Data `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`index`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ResponseWriter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` req `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{`http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`Request`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  templ`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` template`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`ParseFS`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`templates`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` pages`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"list"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!=`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`nil`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
		`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Something went wrong loading the template`}</span>{`
		`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{`
	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  data `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` MyPageData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    Some`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"This is my value"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    Data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Yet another value"`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

  tmpl`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`Execute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`w`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h2>{`Github Actions`}</h2>
    <p>{`To make this app easier to deploy from my Ansible scripts, it would be useful if it could be built for me and easily
accessible.
Of course I could build it locally and reference or copy it to my Ansible repository, or even build it privately with my
Gitea/Drone setup (which is used to build and deploy this website) and serve the binaries from somewhere.`}</p>
    <p>{`I decided to make this project public on Github, so I thought I'd give Github Actions a try seeing as my use case was
likely common and straight forward.
I have been lazy and just built it for x86 linux which is my current use-case.`}</p>
    <p>{`For any new tag that starts with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`v`}</code>{`, the it checks out the repository at that tag, builds the project, creates a new
release, and uploads the built binary to the release.`}</p>
    <span className="prism-title">main.yml</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Satisfactory Viewer

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Only run when a tag is pushed`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`push`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tags`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` v*

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`jobs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`runs-on`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ubuntu`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`latest
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`steps`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Checkout the project (at the tag)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Checkout
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`uses`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` actions/checkout@v2

      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Setup the right version of Go`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Setup Go
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`uses`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` actions/setup`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`go@v2
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`with`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`go-version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1.17.6

      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Run the make file to build the binary`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Build
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`run`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` make build

      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Create a new release`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Create Release
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This ID is specified to reference this step later`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` create_release
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`uses`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` actions/create`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`release@v1
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This token is passed in by Github automatically`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# no token generation is necessary`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`GITHUB_TOKEN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` secrets.GITHUB_TOKEN `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`with`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Both variables are passed in by Github`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`tag_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` github.ref `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`release_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Release $`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` github.ref `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Upload the binary to the newly created release`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Upload Binary
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`uses`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` actions/upload`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`release`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`asset@v1
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`env`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This token is passed in by Github automatically`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# no token generation is necessary`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`GITHUB_TOKEN`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` secrets.GITHUB_TOKEN `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`with`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This references the previous step by ID`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`upload_url`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` $`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` steps.create_release.outputs.upload_url `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# The built binary`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`asset_path`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` ./bin/satisfactory`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`viewer
          `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# What we want it to be named in the release`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`asset_name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` satisfactory`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`viewer
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`asset_content_type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` application/octet`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`stream`}</code></pre></div>
    <p>{`When this runs, a new release is created from the tag, and contains a link to download the asset.`}</p>
    <p>{`I can then use my `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`github_release_download`}</code>{` Ansible module, that I'm yet to release publicly (sorry Bas) to download
the latest release from Github when I run my Satisfactory role.`}</p>
    <h2>{`Versioning`}</h2>
    <p>{`In the past I have found it useful to be able to get the version of something out easily, so I wanted to add this
feature to this program.`}</p>
    <p>{`Apparently there is a new way to do this coming in a future version of Go, but for now this is done using `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ldflags`}</code>{`.
At build time, I can get the current tag (without the leading `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`v`}</code>{`) out of git using this shell command:`}</p>
    <span className="prism-title">shell</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`git`}</span>{` describe --tags --always `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sed`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'s/-/+/'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`sed`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'s/^v//'`}</span></code></pre></div>
    <p>{`Then this can be passed into go build using this:`}</p>
    <span className="prism-title">shell</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`go build -ldflags `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-X main.version=`}<span parentName="span" {...{
              "className": "token variable"
            }}><span parentName="span" {...{
                "className": "token variable"
              }}>{`$(`}</span>{`VERSION`}<span parentName="span" {...{
                "className": "token variable"
              }}>{`)`}</span></span>{`"`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`more arguments`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`This can be made accessible in go by defining the string variable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`version`}</code>{` in the main package.
Then it can be used like any other variable.`}</p>
    <span className="prism-title">go</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "go"
    }}><pre parentName="div" {...{
        "className": "language-go"
      }}><code parentName="pre" {...{
          "className": "language-go"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  version `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`This all comes together in the Makefile:`}</p>
    <span className="prism-title">Makefile</span>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "makefile"
    }}><pre parentName="div" {...{
        "className": "language-makefile"
      }}><code parentName="pre" {...{
          "className": "language-makefile"
        }}>{`VERSION	 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`shell`}</span>{` git describe --tags --always `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` sed `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'s/-/+/'`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` sed `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'s/^v//'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
BUILDOPT `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` -ldflags `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-s -w -X main.version=$(VERSION)"`}</span>{`
SOURCES  `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:=`}</span>{` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`wildcard`}</span>{` *.go`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`build`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` fmt `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`SOURCES`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` clean
	`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`foreach`}</span>{` FILE, `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`SOURCES`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`, echo `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`FILE`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` go build `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`BUILDOPT`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` -o bin/\`basename `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`FILE`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` .go\` `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`FILE`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`fmt`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
	`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`@`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`foreach`}</span>{` FILE, `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`SOURCES`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`, go fmt `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`$`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`FILE`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`clean`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
	rm -f bin/*
`}</code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      